<template>
  <section class="token">
    <h2 class="h2-bold text-left">Memebresías</h2>
    <div class="token__grid">
      <div class="select">
        <label
        class="select__item"
        :class="selected === member.id ? member.color : ''"
        v-for="member in memberships"
        :key="member.id"
        @click="selected = member.id"
        >
          <b-form-radio
            v-model="selected"
            name="some-radios"
            :value="member.id"
          ></b-form-radio>
          <div class="select__text">
            <h3 class="h3-semibold text-white">{{ member.name }}</h3>
            <h3 v-if="member.name != 'Custom'" class="h3-bold text-white">${{coinFormat(member.price)}}</h3>
            <div v-else>
              <b-form-input v-model="query.custom" placeholder="Ingresa el valor" />
              <span style="color: white;">Minimum investment $1000</span>
            </div>
          </div>
        </label>
      </div>
      <!-- Q1 -->
      <div>
        <div v-for="member in memberships" :key="member.id">
          <section
            v-if="selected == member.id"
            class="token__card token__card--blue token__height"
          >
            <video
              class="token__video"
              src="@/assets/video/Plan1.mp4"
              autoplay
              loop
              muted
              selected
            ></video>
            <div class="anim"></div>
            <h3 class="h3-bold text-white">Beneficios {{ member.name }}</h3>
            <div class="token__list">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="token__list-item"
              >
                <p class="l-light text-white">{{ item.item }}</p>
                <i
                  :class="`b7-check-line`"
                  v-if="JSON.parse(member.benefits).includes(item.key)"
                />
                <i :class="`b7-close`" v-else />
              </div>
            </div>
            <b-button
              size="lg"
              variant="primary"
              :disabled="member.name == 'Custom' && query.custom == null"
              @click="
                $router.push({
                  name: 'Purchase-Membership',
                  params: { id: member.id, type: 'membership' },
                  query: query
                })
              "
              > Comprar Paquete </b-button>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      query: {
        custom: null
      },
      items: [
        { item: "Trading plans", key: "Transaction-Book" },
        { item: "Trading Live", key: "Trading-Live" },
        { item: "Signals", key: "Signals" },
        { item: "Academy", key: "Academy" },
        { item: "Crypto Wallets", key: "Wallets" },
        { item: "Team Chat", key: "Chat" },
        { item: "Transaction Book", key: "Transaction-Book" },
        { item: "Referrals Benefits", key: "Referral" },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions("membership", ["getMemberships"]),
    getData() {
      this.getMemberships().then(() => {
        this.selected = this.memberships[0].id;
      });
    },
  },
  computed: {
    ...mapState("membership", ["memberships"]),
  },
};
</script>

<script setup>
import { mapActions, mapState } from "vuex";
</script>

<style scoped>
.token {
  display: grid;
  gap: 2.4rem;
}
.token__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;
}
@media ((max-width: 1100px)) {
  .token__grid {
    grid-template-columns: 1fr;
    gap: 2.4rem;
  }
}
.token__card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
  border-radius: 16px;
}
.token__card--blue {
  border: 1px solid #7d79e7;
  background: rgba(60, 57, 135, 0.1);
}
.token__card--blue .b7-check-line {
  background-color: #3c3987;
  border-radius: 50%;
}
.token__card--blue .b7-close {
  border-radius: 150px;
  background: rgba(253, 236, 236, 0.3);
}
.token__card--green {
  border: 1px solid var(--brand-verde, #48a254);
  background: rgba(73, 135, 73, 0.1);
}
.token__card--green .b7-check-line {
  background-color: #48a254;
  border-radius: 50%;
}
.token__card--green .b7-close {
  border-radius: 150px;
  background: rgba(253, 236, 236, 0.3);
}
.token__card--yellow {
  border: 1px solid var(--inputs-radio-checkbox-toggle-active, #ee8722);
  background: rgba(237, 135, 34, 0.1);
}
.token__card--yellow .b7-check-line {
  background-color: #ee8722;
  border-radius: 50%;
}
.token__card--yellow .b7-close {
  border-radius: 150px;
  background: rgba(253, 236, 236, 0.3);
}
.token__card--cyan {
  border: 1px solid var(--brand-azul-claro, #3bbeee);
  background: rgba(59, 190, 238, 0.1);
}
.token__card--cyan .b7-check-line {
  background-color: #3bbeee;
  border-radius: 50%;
}
.token__card--cyan .b7-close {
  border-radius: 150px;
  background: rgba(253, 236, 236, 0.3);
}
@media ((max-width: 600px)) {
  .token__card {
    padding: 1.2rem;
  }
}
.token__height {
  height: fit-content;
}
.token__list {
  display: grid;
  gap: 1.6rem;
}
.token__list-item {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  justify-content: space-between;
}
.token__list-icon {
  background-color: #3c3987;
  border-radius: 100px;
  width: 2.4rem;
  height: 2.4rem;
  display: grid;
  place-content: center;
}
.token__video {
  width: 100%;
}

.select {
  display: grid;
  gap: 2.4rem;
  height: fit-content;
}
.select__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--brand-border, rgba(255, 255, 255, 0.2));
}
.select__item--blue {
  background-color: #3c3987;
  border: none;
}
.select__item--green {
  background-color: #48a254;
  border: none;
}
.select__text h3 {
  display: flex;
}
i {
  font-size: 24px;
  color: #fff;
}
</style>
